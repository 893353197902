














































































import type { Picture, Link } from '@/inc/types'
import { PropType, defineComponent } from '@vue/composition-api'

interface Hero {
  title: string
  subtitle?: string
  intro?: string
  link?: Link
  video?: string
  videoMobile?: string
  videoMobileFallback?: string
  videoFallback?: string
  picture?: Picture
}

import UiVideoLoop from '@/components/ui/VideoLoop.vue'
import UiPicture from '@/components/ui/Picture.vue'
import UiPromo from '@/components/ui/Promo.vue'

export default defineComponent({
  name: 'HeroDefault',
  components: { UiVideoLoop, UiPicture, UiPromo },
  props: {
    content: {
      type: Object as PropType<Hero>,
      required: true,
    },
    cover: {
      type: Boolean,
      required: false,
      default: false,
    },
    promoLabel: {
      type: [String, null] as PropType<string | null>,
      required: false,
      default: null,
    },
  },

  setup() {
    return {}
  },
})
