var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"blog-hero"},[_c('div',{staticClass:"blog-hero-wrapper wrapper pt-xxl pb-m"},[_c('GCrumbs'),_c('div',{staticClass:"blog-hero__content"},[(_vm.content.label)?_c('div',{staticClass:"blog-hero__content__label small mb-xxs"},[_vm._v(" "+_vm._s(_vm.content.label)+" ")]):_vm._e(),_c('GTitle',{staticClass:"blog-hero__content__title h1--long mb-xxxs",attrs:{"content":_vm.content.displayTitle || _vm.content.title}}),_c('div',{staticClass:"blog-hero__content__subtitle h3 mt-xxxs mb-xxs",domProps:{"innerHTML":_vm._s(_vm.content.subtitle)}}),(_vm.content.author)?_c('UiAuthor',{staticClass:"blog-hero__content__author",attrs:{"content":_vm.content.author}}):(_vm.content.publicationDate)?_c('time',{staticClass:"blog-hero__content__date",attrs:{"datetime":_vm.formatDate(_vm.content.publicationDate, true)}},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.content.publicationDate))+" ")]):_vm._e()],1)],1),(_vm.content.video)?_c('UiVideoLoop',{staticClass:"blog-hero__visual--video",attrs:{"content":{
      video: _vm.content.video,
      videoMobile: _vm.content.videoMobile,
      videoFallback: _vm.content.videoFallback,
      videoMobileFallback: _vm.content.videoMobileFallback,
      picture: _vm.content.picture,
    },"cover":true}}):_c('UiPicture',{staticClass:"blog-hero__visual--picture",attrs:{"cover":true,"content":_vm.content.picture,"sets":[
      '240',
      '320',
      '480',
      '640',
      '960',
      '1280',
      '1600',
      '1920',
      '2240' ]}}),_c('div',{staticClass:"blog-hero__bg",attrs:{"aria-hidden":"true"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }