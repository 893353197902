













































import type { Picture } from '@/inc/types'
import { defineComponent, PropType, onMounted, ref } from '@vue/composition-api'

export interface VideoLoop {
  video: string
  picture?: Picture
  videoMobile?: string
  videoMobileFallback?: string
  videoFallback?: string
}

import UiPicture from '@/components/ui/Picture.vue'

export default defineComponent({
  name: 'UiVideoLoop',
  components: {
    UiPicture,
  },
  props: {
    content: {
      type: Object as PropType<VideoLoop>,
      required: true,
    },
    cover: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(props, { emit }) {
    const rootEl = ref<HTMLElement | null>(null)
    const videoEl = ref<HTMLVideoElement | null>(null)
    const showPicture = ref(true)
    const showMobile = ref(true)
    const picture = ref<InstanceType<typeof UiPicture> | null>(null)

    // Forward picture load event
    const onPictureLoad = () => emit('picture-load')

    // When video is loaded, hide picture and play loop
    const onVideoLoad = () => {
      videoEl.value?.play()
      videoEl.value?.removeEventListener('loadeddata', onVideoLoad)
      showPicture.value = false
      emit('load')
    }

    onMounted(() => {
      // If mobile video is provided and window is under l breakpoint,
      // use mobile video as source
      showMobile.value =
        Boolean(
          props.content.videoMobile || props.content.videoMobileFallback
        ) && window.innerWidth < 1024

      // Wait for video load to hide fallback picture
      if (videoEl.value) {
        // Check if video is already loaded
        if (videoEl.value.readyState >= 3) {
          onVideoLoad()
        } else {
          videoEl.value.addEventListener('loadeddata', onVideoLoad)
          videoEl.value.load()
        }
      }
    })

    return {
      rootEl,
      videoEl,
      showPicture,
      showMobile,
      picture,
      onPictureLoad,
    }
  },
})
