var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"share wrapper"},[_c('div',{directives:[{name:"aware",rawName:"v-aware"}],staticClass:"share-wrapper p-xl",on:{"viewport":_vm.onResize}},[_c('div',{staticClass:"share__label mb-xs"},[_vm._v(_vm._s(_vm.$t('share-long')))]),(_vm.isMobile && _vm.canShare)?_c('div',{staticClass:"share__link"},[_c('GAction',{attrs:{"content":{
          label: _vm.$t('share'),
          tag: 'button',
          modifiers: ['no-label', 'social'],
          icon: 'share',
        }},nativeOn:{"click":function($event){return _vm.share.apply(null, arguments)}}})],1):_c('ul',{staticClass:"share__links"},[_vm._l((_vm.networks),function(link){return _c('li',{key:link.type,staticClass:"share__link"},[_c('GAction',{attrs:{"content":{
            label: _vm.$t(("share-" + (link.type))),
            tag: 'button',
            modifiers: ['no-label', 'social'],
            icon: link.type,
          },"data-share":link.type,"data-label":_vm.$t(("share-" + (link.type))),"aria-abel":_vm.$t(("share-" + (link.type)))},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.onClick.apply(null, arguments)}}})],1)}),(_vm.canCopy)?_c('li',{staticClass:"share__link"},[_c('transition',{attrs:{"name":"tooltip"}},[(_vm.copied)?_c('div',{staticClass:"share__link__tooltip small"},[_vm._v(" "+_vm._s(_vm.$t('copied-to-clipboard'))+" ")]):_vm._e()]),_c('GAction',{attrs:{"content":{
            label: _vm.$t('copy-to-clipboard'),
            tag: 'button',
            modifiers: ['no-label', 'social'],
            icon: 'link',
          }},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.copyToClipboard.apply(null, arguments)}}})],1):_vm._e()],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }