var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hero-default pt-xxl pb-xl",class:{ cover: _vm.cover, 'has-promo': _vm.promoLabel }},[_c('div',{staticClass:"hero-default-inner wrapper"},[_c('GCrumbs',{staticClass:"hero-default__crumbs"}),_c('h1',{staticClass:"hero-default__title h1--long mt-xs",domProps:{"innerHTML":_vm._s(_vm.content.title)}}),(_vm.content.subtitle)?_c('div',{staticClass:"hero-default__subtitle h2--small--light mt-xs",domProps:{"innerHTML":_vm._s(_vm.content.subtitle)}}):_vm._e(),(_vm.content.intro)?_c('div',{staticClass:"hero-default__intro mt-xs",domProps:{"innerHTML":_vm._s(_vm.content.intro)}}):_vm._e(),(_vm.content.link)?_c('GAction',{staticClass:"hero-default__link mt-xs",attrs:{"content":Object.assign({}, _vm.content.link,
        {modifiers: _vm.cover ? ['btn', 'filled'] : ['btn']})}}):_vm._e(),(_vm.promoLabel)?_c('UiPromo',{staticClass:"hero-default__promo",attrs:{"label":_vm.promoLabel}}):_vm._e()],1),(_vm.content.video)?_c('UiVideoLoop',{staticClass:"hero-default__video",attrs:{"content":{
      video: _vm.content.video,
      videoMobile: _vm.content.videoMobile,
      videoMobileFallback: _vm.content.videoMobileFallback,
      videoFallback: _vm.content.videoFallback,
      picture: _vm.content.picture,
    },"cover":true}}):(_vm.content.picture)?_c('UiPicture',{staticClass:"hero-default__picture",attrs:{"cover":true,"content":_vm.content.picture,"sets":[
      '320',
      '480',
      '640',
      '960',
      '1280',
      '1600',
      '1920',
      '2240',
      '2560',
      '2880' ],"deferredLoading":0,"fetchpriority":"high","sizes":"100vw"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }